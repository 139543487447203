import {Notification} from 'element-ui'
// 私有属性，只在当前文件可用
const showNotification = Symbol('showNotification')
export default class domNotification {
	success (options, single = true) {
		this[showNotification]('success', options, single)
	}
	warning(options, single = true) {
		this[showNotification]('warning', options, single)
	}
	info(options, single = true) {
		this[showNotification]('info', options, single)
	}
	error(options, single = false) {
		this[showNotification]('error', options, single)
	}
	[showNotification] (type, options, single) {
		if (single) {
			// 关键代码，判断当前页是否有el-message标签，如果没有则执行弹窗操作
			if (document.getElementsByClassName('el-notification').length === 0) {
				Notification[type](options)
			}
		} else {
			Notification[type](options)
		}
	}
}
